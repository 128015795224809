<template>
  <van-dialog
    v-model="show"
    title="请填写身份信息"
    :show-confirm-button="false"
  >
    <van-form
      @submit="handleSubmit"
      :class="$style.form"
      ref="form"
      label-align="right"
      label-width="32%"
      colon
    >
      <van-field
        v-model="form.name"
        name="姓名"
        label="姓名"
        placeholder="请填写姓名"
        :rules="[{ required: true }]"
      />
      <van-field
        v-model="form.id_number"
        maxlength="18"
        name="身份证"
        label="身份证"
        placeholder="请填写身份证"
        :rules="[{ required: true }]"
      />
      <van-field
        v-model="mobile"
        maxlength="11"
        disabled
        name="手机号"
        label="手机号"
      />
      <van-field
        :class="$style.codeInput"
        v-model="form.sms_code"
        center
        label="短信验证码"
        :rules="[{ required: true }]"
      >
        <template #button>
          <Code
            small
            :mobile="originMobile"
            :verify="verifyObj"
            type="4"
          ></Code>
        </template>
      </van-field>
    </van-form>
    <div class="btnWrap">
      <span class="cancel" @click="show = false">取消</span>
      <span class="sure" @click="handleConfirm"
        ><span class="sure-text">确认</span></span
      >
    </div>
  </van-dialog>
</template>

<script>
import { realVerified, fetchUserInfo } from "@/api";
import { setStorage, getStorage } from "@/utils/util";
import Code from "@/components/Code";

export default {
  components: {
    Code,
  },
  props: {
    showRealVerified: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        name: "",
        id_number: "",
        sms_code: "",
      },
      originMobile: getStorage("user_info").mobile,
    };
  },
  computed: {
    show: {
      get() {
        return this.showRealVerified;
      },
      set(val) {
        this.$emit("update:showRealVerified", val);
      },
    },
    mobile() {
      const m = this.originMobile;
      return m ? m.substring(0, 3) + "****" + m.substring(7) : "";
    },
    verifyObj() {
      return {
        id_number: this.form.id_number,
        name: this.form.name,
      };
    },
  },
  methods: {
    handleConfirm() {
      this.$refs.form.submit();
    },
    handleSubmit() {
      realVerified(this.form).then(() => {
        fetchUserInfo().then((res) => {
          setStorage("user_info", res);
          this.$toast.success("提交成功");
          this.show = false;
          this.$emit("refresh");
        });
      });
    },
  },
};
</script>

<style lang="scss" module>
.form {
  padding: 10px 0 20px 0;
  :global {
    .van-cell {
      padding: 6px 30px 6px 30px;
      background-color: #212121;
      .van-field__label {
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        text-shadow: 0px 3px 8px rgba(0, 0, 0, 0.2);
      }
      .van-field__control {
        box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.2);
        border-radius: 13px;
        border: 1px solid #454545;
        color: #ffffff;
        padding-left: 10px;
        font-size: 14px;
        height: 30px;
      }
      .van-field__button {
        padding-left: 4px;
        .van-button {
          height: 30px;
          line-height: 30px;
          background: #323232;
          box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.4),
            0px 2px 6px 0px rgba(28, 28, 28, 0.3);
          border-radius: 12px;
        }
      }
      &::after {
        display: none;
      }
    }
  }
}
.code {
  width: 60px !important;
  height: 20px !important;
}
</style>
