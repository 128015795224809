<template>
  <div :class="$style.wrap">
    <Back title="设置" />
    <router-link to="/settings/edit" :class="$style.setting"
      ><span>修改个人信息</span> <i :class="$style.icon"></i
    ></router-link>
    <div :class="$style.setting" @click="handle2Real">
      <span>实名认证</span>
      <i :class="$style.icon" v-if="!isReal"></i>
      <span v-else style="color: #3894df">已实名</span>
    </div>
    <router-link to="/settings/agreement" :class="$style.setting">
      <span>用户协议</span>
      <i :class="$style.icon"></i>
    </router-link>
    <router-link to="/settings/privacy" :class="$style.setting">
      <span>隐私设置</span>
      <i :class="$style.icon"></i>
    </router-link>
    <div :class="$style.setting" @click="handleCancelAccount">
      <span>注销账号</span>
      <i :class="$style.icon"></i>
    </div>
    <p :class="$style.record">
      Copyright © 2022 <br />
      <!-- 沪ICP备19040311号-1 -->
      上海青甲智能科技有限公司 版权所有<br />
      沪ICP备19040311号-1
    </p>
    <div :class="$style.logout" @click="handleLogout">退出登录</div>
    <RealVerified
      :showRealVerified.sync="showRealVerified"
      @refresh="initRealStatus"
    />
    <CancelAccount :showCancelAccount.sync="showCancelAccount" />
  </div>
</template>

<script>
import Back from "@/components/Back";
import RealVerified from "@/components/RealVerified";
import CancelAccount from "@/components/CancelAccount";
import { setStorage } from "@/utils/util";
import { fetchUserInfo } from "@/api";
import { logout } from "@/api";
export default {
  components: {
    Back,
    RealVerified,
    CancelAccount,
  },
  data() {
    return {
      showRealVerified: false,
      showCancelAccount: false,
      isReal: false, // 是否实名认证
    };
  },
  created() {
    this.initRealStatus();
  },
  methods: {
    initRealStatus() {
      fetchUserInfo().then((res) => {
        setStorage("user_info", res);
        const isReal = res.name && res.id_number;
        this.isReal = isReal;
      });
    },
    handle2Real() {
      if (this.isReal) return;
      this.showRealVerified = true;
    },
    handleCancelAccount() {
      this.showCancelAccount = true;
    },
    handleLogout() {
      this.$dialog
        .confirm({
          title: "",
          message: "确认退出登录？",
          confirmButtonColor: "#0091FF",
        })
        .then(() => {
          logout().then(() => {
            localStorage.clear();
            this.$router.push("/personal");
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" module>
.wrap {
  .setting {
    width: 335px;
    height: 50px;
    padding: 0 20px;
    box-sizing: border-box;
    background: #212121;
    border-radius: 16px;
    margin: 0 auto 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    color: #fff;
    &:nth-of-type(1) {
      margin-top: 20px;
    }
    .icon {
      width: 10px;
      height: 12px;
      background: url("~@/assets/img/icon-arrow.png");
      background-size: contain;
    }
  }
  .record {
    position: fixed;
    bottom: 100px;
    width: 100%;
    font-weight: 400;
    text-align: center;
    font-size: 11px;
    color: #949494;
    line-height: 16px;
  }
  .logout {
    position: fixed;
    bottom: 36px;
    left: 20px;
    width: 335px;
    height: 44px;
    line-height: 44px;
    text-align: center;
    background: #212121;
    border-radius: 28px;
  }
}
</style>
