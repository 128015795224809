// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/img/icon-arrow.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".index_wrap_39uhR .index_setting_1n0n2{width:8.93333rem;height:1.33333rem;padding:0 .53333rem;box-sizing:border-box;background:#212121;border-radius:.42667rem;margin:0 auto .26667rem;display:flex;justify-content:space-between;align-items:center;font-weight:500;color:#fff}.index_wrap_39uhR .index_setting_1n0n2:first-of-type{margin-top:.53333rem}.index_wrap_39uhR .index_setting_1n0n2 .index_icon_3tYE3{width:.26667rem;height:.32rem;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:contain}.index_wrap_39uhR .index_record_3kyLF{bottom:2.66667rem;width:100%;font-weight:400;font-size:.29333rem;color:#949494;line-height:.42667rem}.index_wrap_39uhR .index_logout_3bGUl,.index_wrap_39uhR .index_record_3kyLF{position:fixed;text-align:center}.index_wrap_39uhR .index_logout_3bGUl{bottom:.96rem;left:.53333rem;width:8.93333rem;height:1.17333rem;line-height:1.17333rem;background:#212121;border-radius:.74667rem}", ""]);
// Exports
exports.locals = {
	"wrap": "index_wrap_39uhR",
	"setting": "index_setting_1n0n2",
	"icon": "index_icon_3tYE3",
	"record": "index_record_3kyLF",
	"logout": "index_logout_3bGUl"
};
module.exports = exports;
