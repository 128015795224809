<template>
  <van-dialog v-model="show" :show-confirm-button="false">
    <p slot="title" :class="$style.title">
      <img src="@/assets/img/warning.png" :class="$style.icon" /> 注销账号
    </p>
    <p :class="$style.tip">注意：注销账号将会清空所有数据！确认注销？</p>
    <van-form
      @submit="handleSubmit"
      :class="$style.form"
      ref="form"
      label-align="right"
      label-width="32%"
      colon
    >
      <van-field
        v-model="mobile"
        maxlength="11"
        disabled
        name="手机号"
        label="手机号"
      />
      <van-field
        :class="$style.codeInput"
        v-model="form.sms_code"
        center
        label="短信验证码"
        :rules="[{ required: true }]"
      >
        <template #button>
          <Code small :mobile="originMobile" type="3"></Code>
        </template>
      </van-field>
    </van-form>
    <div class="btnWrap">
      <span class="cancel" @click="handleConfirm"
        ><span class="sure-text">仍要注销</span></span
      >
      <span class="sure" @click="show = false">我在想想</span>
    </div>
  </van-dialog>
</template>

<script>
import { cancelAccount } from "@/api";
import { getStorage } from "@/utils/util";
import Code from "@/components/Code";

export default {
  components: {
    Code,
  },
  props: {
    showCancelAccount: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        sms_code: "",
      },
      originMobile: getStorage("user_info").mobile,
    };
  },
  computed: {
    show: {
      get() {
        return this.showCancelAccount;
      },
      set(val) {
        this.$emit("update:showCancelAccount", val);
      },
    },
    mobile() {
      const m = this.originMobile;
      return m ? m.substring(0, 3) + "****" + m.substring(7) : "";
    },
  },
  methods: {
    handleConfirm() {
      this.$refs.form.submit();
    },
    handleSubmit() {
      cancelAccount({
        mobile: this.originMobile,
        sms_code: this.form.sms_code,
      }).then(() => {
        this.$toast.success("注销成功");
        this.show = false;
        this.$router.push("/login");
      });
    },
  },
};
</script>

<style lang="scss" module>
.title {
  display: flex;
  align-items: center;
  justify-content: center;
  .icon {
    width: 20px;
    height: 20px;
    margin-right: 7px;
  }
}
.tip {
  width: 256px;
  margin: 17px auto 0;
  text-align: center;
  height: 44px;
  font-size: 16px;
  color: #ffffff;
  line-height: 22px;
  text-shadow: 0px 3px 8px rgba(0, 0, 0, 0.5);
}
.form {
  padding: 10px 0 20px 0;
  :global {
    .van-cell {
      padding: 6px 30px 6px 30px;
      background-color: #212121;
      .van-field__label {
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        text-shadow: 0px 3px 8px rgba(0, 0, 0, 0.2);
      }
      .van-field__control {
        box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.2);
        border-radius: 13px;
        border: 1px solid #454545;
        color: #ffffff;
        padding-left: 10px;
        font-size: 14px;
        height: 30px;
      }
      .van-field__button {
        padding-left: 4px;
        .van-button {
          height: 30px;
          line-height: 30px;
          background: #323232;
          box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.4),
            0px 2px 6px 0px rgba(28, 28, 28, 0.3);
          border-radius: 12px;
        }
      }
      &::after {
        display: none;
      }
    }
  }
}
.code {
  width: 60px !important;
  height: 20px !important;
}
</style>
